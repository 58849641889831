import { createBrowserRouter, redirect } from "react-router-dom";
import { lazy } from 'react'

import ProtectedRoute from "../components/ProtectedRoute"
import UnProtectedRoute from "../components/UnprotectedRoute";
// import Calendar from "../pages/calendar";
// import Teams from "../pages/teams";
import IndexRoute from "../components/IndexRoute";
import ReAuth from "../pages/reauth";
import AppRedirect from "../pages/app-redirect";
import { ClientRoutes } from "./clients";
import { AdminRoutes } from "./admin";

// import Deductions from "../pages/deductions";
// import Department from "../pages/department";
// import Documents from "../pages/documents";

const Login = lazy(() => import('../pages/login'))
const ForgotPage = lazy(() => import('../pages/forgot'))
const SignUpPage = lazy(() => import('../pages/sign-up'))
const ErrorPage = lazy(() => import('../pages/error'))
const Root = lazy(() => import('../pages/root'))

const PayInvoice = lazy(() => import('../pages/payment/invoice'))

export const router = createBrowserRouter([
    {
        path: "/",
        caseSensitive: true,
        element: <IndexRoute />,
    },
    {
        path: "/client",
        element: <Root />,
        loader: ({ params, request }) => {
            const { url } = request
            const path = url.split('/client/')[1]

            console.log(path)

            return { params, request }
        },
        children: ClientRoutes
    },
    {
        path: "/dashboard",
        element: <Root />,
        loader: ({ params, request }) => {
            const { url } = request
            const path = url.split('/dashboard/')[1]

            if (path === undefined || path === '') {
                throw redirect("/dashboard/overview")
            }

            return { params, request }
        },
        children: AdminRoutes,
        errorElement: <ErrorPage />,
    },
    { path: "/login", element: <Login />, },
    { path: "/reset-password", element: <UnProtectedRoute children={<ForgotPage />} />, },
    { path: "/sign-up", element: <UnProtectedRoute children={<SignUpPage />} />, },
    { path: "/reauth", element: <ProtectedRoute children={<ReAuth />} /> },
    { path: "/jobs/:jobId", element: <AppRedirect />, },
    { path: "/pay/invoice/:invoiceId", element: <PayInvoice /> }
]);